import React, { useRef, useCallback, useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styles from '../css/support-page.module.css'

const SupportPage = ({ data, location }) => {

    const returnPolicyRef = useRef();
    const termsOfServiceRef = useRef();
    const privacyPolicyRef = useRef();

    const [currentSection, setCurrentSection] = useState('')

    const initializeState = useCallback(() => {
        let screenSize = window.innerWidth;
        let headerOffset = screenSize >= 992 ? 90 : 130;
        let termsSection = termsOfServiceRef.current.offsetTop;
        let privacySection = privacyPolicyRef.current.offsetTop;
        let windowPosition = window.scrollY;
        if (windowPosition < termsSection - headerOffset ) {
            setCurrentSection('returns')
        }
        if (windowPosition >= termsSection - headerOffset && windowPosition < privacySection - headerOffset) {
            setCurrentSection('terms')
        }
        if (windowPosition > privacySection - headerOffset) {
            setCurrentSection('privacy')
        }
    }, [termsOfServiceRef, privacyPolicyRef]);

    const handleScroll = useCallback(() => {
        let screenSize = window.innerWidth;
        let headerOffset = screenSize >= 992 ? 90 : 130;
        let termsSection = termsOfServiceRef.current.offsetTop;
        let privacySection = privacyPolicyRef.current.offsetTop;
        let windowPosition = window.scrollY;
        if (windowPosition < termsSection - headerOffset ) {
            setCurrentSection('returns')
        }
        if (windowPosition >= termsSection - headerOffset && windowPosition < privacySection - headerOffset) {
            setCurrentSection('terms')
        }
        if (windowPosition > privacySection - headerOffset) {
            setCurrentSection('privacy')
        }
    }, [termsOfServiceRef, privacyPolicyRef]);

    useEffect(() => {
        initializeState();

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }

    },[handleScroll, initializeState])

    const mobileScrollTo = (ref) => {
        let headerOffset = 120;
        let elementPosition = ref.current.offsetTop;
        let offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

    }

    const desktopScrollTo = (ref) => {
        let headerOffset = 80;
        let elementPosition = ref.current.offsetTop;
        let offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

   }

   const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize

    return(
        <Layout>
            <SEO
                title="Support"
                description="Rare Threads Support Hub. Reach us at hello@rarechicago.com."
                image={socialCardImage}
                pathname={location.pathname}
            />

            <div className="max-viewport mx-auto laptop:flex">

            {/* Support Navigation Menu */}
            <div className={`${styles.supportMenu} bg-seagreen laptop:bg-transparent laptop:flex items-center justify-center`}>

                {/* Mobile Nav Links */}
                <div className={`flex w-full text-black laptop:hidden`}>
                    <div className="w-1/3 flex items-center justify-around" style={{ height: '60px' }}>
                        <button
                            className={`${styles.mobileNavLink} ${currentSection === 'returns' ? styles.mobileActiveLink : ``} font-bold`}
                            onClick={() => mobileScrollTo(returnPolicyRef)}
                        >
                            Returns
                        </button>
                    </div>
                    <div className="w-1/3 flex items-center justify-around" style={{ height: '60px' }}>
                        <button
                            className={`${styles.mobileNavLink} ${currentSection === 'terms' ? styles.mobileActiveLink : ``} font-bold`}
                            onClick={() => mobileScrollTo(termsOfServiceRef)}
                        >
                            Service
                        </button>
                    </div>
                    <div className="w-1/3 flex items-center justify-around" style={{ height: '60px' }}>
                        <button
                            className={`${styles.mobileNavLink} ${currentSection === 'privacy' ? styles.mobileActiveLink : ``} font-bold`}
                            onClick={() => mobileScrollTo(privacyPolicyRef)}
                        >
                            Privacy
                        </button>
                    </div>
                </div>


                {/* Desktop Nav Links */}
                <nav className="hidden laptop:block fixed">
                    <ul className={styles.desktopNavLinkContainer}>
                        <li className="mb-6">
                            <button
                                className={`${styles.desktopNavLink} ${currentSection === 'returns' ? styles.desktopActiveLink : ``} font-bold`}
                                onClick={() => desktopScrollTo(returnPolicyRef)}
                            >
                                Return Policy
                            </button>
                        </li>
                        <li className="mb-6">
                            <button
                                className={`${styles.desktopNavLink} ${currentSection === 'terms' ? styles.desktopActiveLink : ``} font-bold`}
                                onClick={() => desktopScrollTo(termsOfServiceRef)}
                            >
                                Terms of Service
                            </button>
                        </li>
                        <li className="mb-6">
                            <button
                                className={`${styles.desktopNavLink} ${currentSection === 'privacy' ? styles.desktopActiveLink : ``} font-bold`}
                                onClick={() => desktopScrollTo(privacyPolicyRef)} >Privacy Policy</button>
                        </li>
                    </ul>
                </nav>
            </div>

            {/* Support Main Content */}
            <div className={styles.mainContent}>

                {/* Return Policy */}
                <div ref={returnPolicyRef} className="flex bg-white mb-6 pt-6 justify-around">
                    <h2 className="text-dkblue">Return Policy</h2>
                </div>
                <div className="page-padding laptop:px-6 desktop:px-14">
                    <p>We have a 15-day return policy, which means you have 15 days after receiving your item to request a return.</p>
                    <p>To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.</p>
                    <p>To start a return, you can contact us at <a className="text-linkcolor" href="mailto: hello@rarechicago.com">hello@rarechicago.com</a>. If your return is accepted, we’ll send you instructions on how and where to send your package. Items sent back without first requesting a return will not be accepted.</p>
                    <p>You can always contact us for any return question at <a className="text-linkcolor" href="mailto: hello@rarechicago.com">hello@rarechicago.com</a>.</p>
                    <h3>Damages and Issues</h3>
                    <p>Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.</p>
                    <h3>Exchanges</h3>
                    <p>The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.</p>
                    <h3>Refunds</h3>
                    <p>You will be notified once your return is received and inspected. If approved, you’ll be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too.</p>
                </div>

                {/* Terms of Service */}
                <div ref={termsOfServiceRef} className="flex bg-white mb-6 pt-6 justify-around">
                    <h2 className="text-dkblue">Terms of Service</h2>
                </div>
                <div className="page-padding laptop:px-6 desktop:px-14">
                    <h3>Overview</h3>
                    <p>This website is operated by Rare Chicago. Throughout the site, the terms “we”, “us” and “our” refer to Rare Chicago. Rare Chicago offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                    <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                    <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                    <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                    <p>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.</p>
                    <h3>Online Store Terms</h3>
                    <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                    <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
                    <p>You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
                    <h3>General Conditions</h3>
                    <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                    <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
                    <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
                    <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
                    <h3>Accuracy, Completeness, and Timeliness of Information</h3>
                    <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
                    <p>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
                    <h3>Modifications to the Service and Prices</h3>
                    <p>Prices for our products are subject to change without notice.</p>
                    <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
                    <p>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
                    <h3>Products or Services (if applicable)</h3>
                    <p>Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</p>
                    <p>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.</p>
                    <p>We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time.</p>
                    <h3>Accuracy of Billing and Account Information</h3>
                    <p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</p>
                    <p>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
                    <p>For more detail, please review our Returns Policy.</p>
                    <h3>Personal Information</h3>
                    <p>Your submission of personal information through the store is governed by our <Link to="/privacy/" className="text-linkcolor">Privacy Policy</Link>.</p>
                    <h3>Errors, Innaccuracies, and Omissions</h3>
                    <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
                    <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
                    <h3>Prohibited Uses</h3>
                    <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                    <h3>Disclaimer of Warranties; Limitation of Liability</h3>
                    <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</p>
                    <p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
                    <p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</p>
                    <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                    <p>In no case shall Rare Chicago, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
                    <h3>Indemnification</h3>
                    <p>You agree to indemnify, defend and hold harmless Rare Chicago and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                    <h3>Severability</h3>
                    <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                    <h3>Termination</h3>
                    <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                    <p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</p>
                    <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                    <h3>Entire Agreement</h3>
                    <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>
                    <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>
                    <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
                    <h3>Governing Law</h3>
                    <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of the United States.</p>
                    <h3>Changes to Terms of Service</h3>
                    <p>You can review the most current version of the Terms of Service at any time at this page.</p>
                    <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                    <h3>Contact Information</h3>
                    <p>Questions about the Terms of Service should be sent to us at <a className="text-linkcolor" href="mailto: hello@rarechicago.com">hello@rarechicago.com</a>.</p>
                </div>

                {/* Privacy Policy */}
                <div ref={privacyPolicyRef} className="flex bg-white mb-6 pt-6 justify-around">
                    <h2 className="text-dkblue">Privacy Policy</h2>
                </div>
                <div className="page-padding laptop:px-6 desktop:px-14">
                    <p>This Privacy Policy describes how shop.rarechicago.com (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.</p>
                    <h3>Collecting Personal Information</h3>
                    <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.</p>
                    <p className="underline font-bold">Device information</p>
                    <ul>
                        <li>
                            <p><b>Examples of Personal Information collected:</b> version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.</p>
                        </li>
                        <li>
                            <p><b>Purpose of collection:</b> to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.</p>
                        </li>
                        <li>
                            <p><b>Source of collection:</b> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels.</p>
                        </li>
                        <li>
                            <p><b>Disclosure for a business purpose:</b> shared with our processor Shopify, and our supplier(s) Printful.</p>
                        </li>
                    </ul>
                    <p className="underline font-bold">Order information</p>
                    <ul>
                        <li>
                            <p><b>Examples of Personal Information collected:</b> name, billing address, shipping address, payment information including credit card numbers, email address, and phone number.</p>
                        </li>
                        <li>
                            <p><b>Purpose of collection:</b> to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
                        </li>
                        <li>
                            <p><b>Source of collection:</b> collected from you.</p>
                        </li>
                        <li>
                            <p><b>Disclosure for a business purpose:</b> shared with our processor Shopify, and our supplier(s) Printful.</p>
                        </li>
                    </ul>
                    <h3>Minors</h3>
                    <p>The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.</p>
                    <h3>Sharing Personal Information</h3>
                    <p>We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:</p>
                    <ul>
                        <li>
                            <p>We use Shopify to power our online store. You can read more about how Shopify uses your Personal Information see <a className="text-linkcolor" href="https://www.shopify.com/legal/privacy">Shopify's legal policy</a>.</p>
                        </li>
                        <li>
                            <p>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
                        </li>
                    </ul>
                    <h3>Using Personal Information</h3>
                    <p>We use your personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.</p>
                    <h3>Lawful basis</h3>
                    <p>Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process your personal information under the following lawful bases:</p>
                    <ul>
                        <li>
                            <p>Your consent;</p>
                        </li>
                        <li>
                            <p>The performance of the contract between you and the Site;</p>
                        </li>
                        <li>
                            <p>Compliance with our legal obligations;</p>
                        </li>
                        <li>
                            <p>To protect your vital interests;</p>
                        </li>
                        <li>
                            <p>To perform a task carried out in the public interest;</p>
                        </li>
                        <li>
                            <p>For our legitimate interests, which do not override your fundamental rights and freedoms.</p>
                        </li>
                    </ul>
                    <h3>Retention</h3>
                    <p>When you place an order through the Site, we will retain your Personal Information for our records unless and until you ask us to erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.</p>
                    <h3>Automatic Decision Making</h3>
                    <p>If you are a resident of the EEA, you have the right to object to processing based solely on automated decision-making (which includes profiling), when that decision-making has a legal effect on you or otherwise significantly affects you.</p>
                    <p>We do not engage in fully automated decision-making that has a legal or otherwise significant effect using customer data.</p>
                    <p>Our processor Shopify uses limited automated decision-making to prevent fraud that does not have a legal or otherwise significant effect on you.</p>
                    <p>Services that include elements of automated decision-making include:</p>
                    <ul>
                        <li>
                            <p>Temporary denylist of IP addresses associated with repeated failed transactions. This denylist persists for a small number of hours.</p>
                        </li>
                        <li>
                            <p>Temporary denylist of credit cards associated with denylisted IP addresses. This denylist persists for a small number of days.</p>
                        </li>
                    </ul>
                    <h3>Your rights</h3>
                    <p className="underline font-bold">GDPR</p>
                    <p>If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information below.</p>
                    <p>Your Personal Information will be initially processed in Ireland and then will be transferred outside of Europe for storage and further processing, including to Canada and the United States. For more information on how data transfers comply with the GDPR, see <a className="text-linkcolor" href="https://help.shopify.com/en/manual/your-account/privacy/GDPR">Shopify’s GDPR Whitepaper</a>.</p>
                    <h3>Cookies</h3>
                    <p>A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.</p>
                    <p>The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.</p>
                    <p>You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your user experience and parts of our website may no longer be fully accessible.</p>
                    <p>Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often found in your browser’s “Tools” or “Preferences” menu. More information on how to modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help file or through such sites as <a className="text-linkcolor" href="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</a>.</p>
                    <h3>Do Not Track</h3>
                    <p>Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.</p>
                    <h3>Changes</h3>
                    <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>
                    <h3>Contact</h3>
                    <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a className="text-linkcolor" href="mailto: hello@rarechicago.com">hello@rarechicago.com</a>.</p>
                </div>

            </div>
            </div>
        </Layout>
    )
}

export default SupportPage;

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarethreads-socialcard-support"}}) {
        edges {
            node {
            childImageSharp {
                resize(width: 1200) {
                src
                height
                width
                }
            }
            }
        }
        }
    }
`